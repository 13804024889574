<template>
  <div id="gl-account--table">
    <!-- @click:row="editItem" 
      class="hb-data-table hb-data-table-cursor-on"
      -->
    <hb-data-table
      :headers="headers"
      :items="bulkEdit ? editableData:accounts"
      :disablePagination="bulkEdit"
      :hideDefaultFooter="bulkEdit"
      @click:row="editItem"
    >
      <template v-slot:item.code="{ item }">
        <div v-if="bulkEdit">
          <v-text-field
              single-line
              :error-messages="errors.first('gl_code_'+item.index)"
              hide-details
              v-model="item.code"
              label="Enter Code"
              :id="`gl_code_${item.index}`"
              :name="`gl_code_${item.index}`"
              v-validate="'required|max:45'"
              class="py-0 ma-0"
          ></v-text-field>
        </div>
        <div v-else>
          {{item.code}}
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div v-if="bulkEdit">
          <v-text-field
            single-line
            :error-messages="errors.first('name_'+item.index)"
            hide-details
            v-model="item.name"
            label="Enter a Name for this Account"
            :id="`name_${item.index}`"
            :name="`name_${item.index}`"
            v-validate="'required|max:100'"
            class="py-0 ma-0"
          ></v-text-field>
        </div>
        <div v-else>
          {{item.name}}
        </div>
      </template>

      <template v-slot:item.category="{ item }">
        <div v-if="bulkEdit">
          <v-select
            :error-messages="errors.first('category_'+item.index)"
            :items="categories"
            hide-details
            single-line
            label="No Selection"
            v-validate="'required'"
            item-text="name"
            item-value="id"
            :id="`category_${item.index}`"
            :name="`category_${item.index}`"
            v-model="item.category_id"
            class="pt-0 hb-text-night"
            @change="onCategoryChange(item.index)"
          />
        </div>
        <div v-else>
          {{item.category}}
        </div>
      </template>

      <template v-slot:item.account_type="{ item }">
        <div v-if="bulkEdit">
          <v-select
            :error-messages="errors.first('account_type_'+item.index)"
            :items="item.account_types"
            hide-details
            single-line
            label="No Selection"
            v-validate="'required'"
            item-text="name"
            item-value="id"
            :id="`account_type_${item.index}`"
            :name="`account_type_${item.index}`"
            v-model="item.account_type_id"
            @change="onAccountTypeChange(item.index)"
            class="pt-0 hb-text-night"
          />
        </div>
        <div v-else>
          {{item.account_type}}
        </div>
      </template>

      <template v-slot:item.account_subtype="{ item }">
        <div v-if="bulkEdit">
          <v-select
            :error-messages="errors.first('account_subtype_'+item.index)"
            :items="item.account_subtypes"
            hide-details
            single-line
            label="No Selection"
            v-validate="'required'"
            item-text="name"
            item-value="id"
            :id="`account_subtype_${item.index}`"
            :name="`account_subtype_${item.index}`"
            v-model="item.account_subtype_id"
            class="pt-0 hb-text-night"
          />
        </div>
        <div v-else>
          {{item.account_subtype}}
        </div>
      </template>
     

      <template v-slot:no-data>
        <v-row class="d-flex flex-column my-4" no-gutters>
          <v-col>
            <span class="font-weight-regular hb-text-night">No Information</span>
          </v-col>
          <!-- <v-col>
            <a class="hb-link">Click Here to Upload Spread Sheet Template</a>
          </v-col> -->
        </v-row> 
      </template>

      <template v-if="hasPermission('manage_gl_accounts')" v-slot:item.actions="{ item }">
        <div v-if="bulkEdit">
          <hb-btn
            icon
            tooltip="Delete"
            active-state-off
            @click="removeItem(item.index)"
          >
              mdi-close
          </hb-btn>
        </div>

        <hb-menu options v-else>

            <v-list>

              <v-list-item @click="$emit('editItem',item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>

              <v-list-item @click="$emit('deleteItem',item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>

            </v-list>
            
          </hb-menu>

      </template>

      <template v-slot:footer="{ footer }" v-if="bulkEdit">
        <v-divider></v-divider>
        <v-row class="text-right mt-2 mb-n4">
          <v-col class="d-flex align-center justify-end">
            <a class="hb-link mr-3" @click="$emit('cancelBulkEdit')">Cancel</a>
            <hb-btn color="primary" @click="saveBulk">Save</hb-btn>
          </v-col>
        </v-row>
      </template>

    </hb-data-table>
  </div>
</template>

<script>

import api from '../../../assets/api.js';
import { mapGetters } from "vuex";

export default {
  name: 'GlAccounts',
  data() {
    return {
      headers: [
        { text: "GL Code", value: "code", width:'120' },
        { text: "GL Name", value: "name", width:'200' },
        { text: "Category", value: "category", width:'130' },
        { text: "Type", value: "account_type", width:'170' },
        { text: "Subtype", value: "account_subtype", width:'170' },
        { text: "", value: "actions", align: "right", sortable: false, width: '10' }
      ],
      categories: [],
      account_types: [],
      account_subtypes: [],
      editableData: []
    }
  },
  props: {
    accounts: {
      type: Array,
      default: []
    },
    bulkEdit: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
  },
  methods: {
    async fetchAccountCategories(){
      let p = await api.get(this, api.ACCOUNTING + 'categories');
      this.categories = p.categories
    },
    
    async fetchAllAccountTypes(){
      let p = await api.get(this, api.ACCOUNTING + 'account-types');
      return p.account_types;
    },

    async fetchAllAccountSubTypes(){
      let p = await api.get(this, api.ACCOUNTING + 'account-subtypes');
      return p.account_subtypes;
    },

    removeItem(id) {
      let index =  this.editableData.findIndex(x=>x.index === id);
      this.editableData.splice(index,1);
    },

    onCategoryChange(account_id_index) {
      let index =  this.editableData.findIndex(x=>x.index === account_id_index);
      this.editableData[index].account_type_id = null;
      this.editableData[index].account_subtype_id = null;

      this.editableData[index].account_subtypes = [];
      this.editableData[index].account_types = this.account_types.filter(x=> x.gl_category_id === this.editableData[index].category_id);
    },

    onAccountTypeChange(account_id_index) {
      let index =  this.editableData.findIndex(x=>x.index === account_id_index);
      this.editableData[index].account_subtype_id = null;
      this.editableData[index].account_subtypes = this.account_subtypes.filter(x=> x.gl_account_type_id === this.editableData[index].account_type_id);
    },

    async saveBulk() {
      let status = await this.validate(this);

      if(!status) return;

      let data = this.editableData.map(x => {
        return {
          id: x.id,
          code:  x.code,
          name:  x.name,
          category_id:  x.category_id,
          account_type_id:  x.account_type_id,
          account_subtype_id: x.account_subtype_id,
          upload_id: x.upload_id,
          index: x.index
        }
      });
      this.$emit('saveBulk', data);
      
      //TODO: code needs to be removed
      // let errors = this.validateSubtype();
      // if(!errors.length){
      //   this.$emit('saveBulk',data);
      // }else{
      //   this.$store.dispatch("notificationsStore/setErrorNotification", {
      //     id: this.$options.name,
      //     formErrors: [{ msg: 'Mutiple accounts of same subtype found. Please correct this.' }],
      //   });
      //   return;
      // }
    },

    //TODO: code needs to be removed
    // validateSubtype(){
    //   let sub_types = [];
    //   let errors = [];
    //   let data = this.editableData.map(x => {
    //     return {
    //       id: x.id,
    //       code:  x.code,
    //       name:  x.name,
    //       account_subtype_id: x.account_subtype_id,
    //       index: x.index
    //     }
    //   });
    //   data.forEach(x => {
    //     if(sub_types.includes(x.account_subtype_id)){
    //       errors.push(x);
    //     }else{
    //       sub_types.push(x.account_subtype_id);
    //     }
    //   })
    //   return errors;
    // }
    editItem(item) {
      if (this.hasPermission("manage_gl_accounts") && !bulkEdit) {
        this.$emit("editItem", item);
      }
    },
  },
  watch: {
    async bulkEdit() {
      if(this.bulkEdit){
        await this.fetchAccountCategories();
        this.account_types = await this.fetchAllAccountTypes();
        this.account_subtypes = await this.fetchAllAccountSubTypes();

        let temp = JSON.parse(JSON.stringify(this.accounts));
        for(let i=0; i<temp.length; i++) {
          temp[i]['account_types'] = this.account_types.filter(x=> x.gl_category_id === temp[i].category_id);
          temp[i]['account_subtypes'] = this.account_subtypes.filter(x=>x.gl_account_type_id === temp[i].account_type_id);
        }
        this.editableData = temp;
      }
    }
  },

}
</script>

<style lang="scss">
  #gl-account--table {
    table {
      thead {
        height:40px;
      }
    }
  }
  // .text-start {
  //   padding-right: 0px !important;
  // }
</style>