<template>

    <hb-modal 
        v-model="dialog"
        size="large" 
        :title="isEdit ? 'Edit Account' : 'Add New Account'"
        @close="$emit('close')"
        show-help-link
    >
        <template v-slot:subheader>
           <span v-if="isEdit">Click on any section to make any necessary changes.</span> 
           <span v-else>Fill out the fields below to add a new account to your chart of accounts.</span>
        </template>
        <template v-slot:content>

            <hb-form label="GL Code" required>
                <v-text-field
                    single-line
                    :error-messages="errors.first('gl_code')"
                    hide-details
                    v-model="new_account.code"
                    label="Enter Code Value"
                    id="gl_code"
                    name="gl_code"
                    v-validate="'required|max:45'"
                    data-vv-as="code"
                    :class="{ 'custom-field-error': errors.first('gl_code') }"
                ></v-text-field>
            </hb-form>

            <hb-form label="GL Name" required>
                <v-text-field
                    single-line
                    :error-messages="errors.first('name')"
                    hide-details
                    v-model="new_account.name"
                    label="Enter a Name for this Account"
                    id="name"
                    name="name"
                    v-validate="'required|max:100'"
                    data-vv-as="name"
                    :class="{ 'custom-field-error': errors.first('name') }"
                ></v-text-field>
            </hb-form>

            <hb-form label="Category" required>
                <v-select
                    :error-messages="errors.first('category')"
                    :items="categories"
                    hide-details
                    single-line
                    label="No Selection"
                    v-validate="'required'"
                    id="category"
                    item-text="name"
                    item-value="id"
                    name="category"
                    data-vv-as="category"
                    v-model="new_account.category_id"
                    @change="fetchAccountTypes"
                    clearable
                    :class="{
                        'custom-field-error': errors.first('category'),
                    }"
                />
            </hb-form>

            <hb-form label="Type" required>
                <v-select
                    :error-messages="errors.first('account_type')"
                    :items="account_types"
                    hide-details
                    single-line
                    label="No Selection"
                    v-validate="'required'"
                    id="account_type"
                    name="account_type"
                    data-vv-as="type"
                    v-model="new_account.account_type_id"
                    item-text="name"
                    item-value="id"
                    @change="fetchAccountSubTypes"
                    :disabled="isLoading($options.name) || !new_account.category_id"
                    clearable
                    :class="{
                        'custom-field-error': errors.first('account_type'),
                    }"
                />
            </hb-form>

            <hb-form label="Subtype" required :divider="false" last>
                <v-select
                    :error-messages="errors.first('account_subtype')"
                    :items="account_subtypes"
                    hide-details
                    single-line
                    label="No Selection"
                    id="account_subtype"
                    name="account_subtype"
                    v-validate="'required'"
                    data-vv-as="subtype"
                    v-model="new_account.account_subtype_id"
                    item-text="name"
                    item-value="id"
                    clearable
                    :disabled="isLoading($options.name) || !new_account.account_type_id"
                    :class="{ 'custom-field-error': errors.first('account_subtype') }"
                />
            </hb-form>

        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="saveNewAccount">Save</hb-btn>
            <span v-show="isLoading($options.name)">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>     
        </template>
    </hb-modal>
</template>

<script>

    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import Templates from '../Templates.vue';
    import HoverableEditField from "../../assets/HoverableEditField.vue";
    import { EventBus } from '../../../EventBus.js';
    import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
    name : 'EditAccount',
    components : {
        Status,
        Loader,
        Templates,
        HoverableEditField
    },
    mixins:[notificationMixin],
    props : {
        accounts: {
            type: Array,
            default: []
        },
        selected : {
            type : Object,
            default : null
        },
        isShown : {
            type : Boolean,
            type : true
        },
        value: {
            type: Boolean
        }
    },
    data() {
        return {
            new_account: {
                id: null,
                code: '',
                name: '',
                category_id: null,
                account_type_id: null,
                account_subtype_id: null
            },
            account_types: [],
            account_subtypes: [],
            categories: [],
            isEdit : false,
            activelyEditing:''
        }
    },
    async created() {
        EventBus.$on('saveData', this.saveNewAccount);
        EventBus.$on('activeEdit', this.setActiveEditName);
        await this.prepareData();
    },
    destroyed () {
        EventBus.$off('activeEdit', this.setActiveEditName);
        EventBus.$off('saveData', this.saveNewAccount);
        this.closeMessageNotification();
    },
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        emitClose(){
            setTimeout(() => {
                this.$emit('close');
            }, 250);
        },
        async prepareData(){
            if (this.selected && Object.keys(this.selected).length > 0) {
                this.new_account = JSON.parse(JSON.stringify(this.selected))
                this.isEdit = true;
                await this.fetchAccountCategories();
                await this.fetchAccountTypes();
                await this.fetchAccountSubTypes();
            } else {
                await this.fetchAccountCategories();
            }
        },
        async fetchAccountTypes(isEdit){

            if(!this.new_account.category_id) return;
            if(isEdit) {
                this.new_account.account_type_id = null;
                this.new_account.account_subtype_id = null;
            }

            let p = await api.get(this, api.ACCOUNTING + 'account-types?category_id='+this.new_account.category_id);
            this.account_types = p.account_types
        },
        async fetchAccountCategories(){
            let p = await api.get(this, api.ACCOUNTING + 'categories');
            this.categories = p.categories
        },
        async fetchAccountSubTypes(isEdit){

            if(!this.new_account.account_type_id) return;
            if(isEdit) this.new_account.account_subtype_id = null;

            let p = await api.get(this, api.ACCOUNTING + 'account-subtypes?account_type_id='+this.new_account.account_type_id);
            this.account_subtypes = p.account_subtypes
        },
        saveNewAccount(){

            this.validate(this).then(status => {

                if(!status) return;

                if(!this.isLoading(this.$options.name)){

                    let data = {
                        code:  this.new_account.code,
                        name:  this.new_account.name,
                        category_id:  this.new_account.category_id,
                        account_type_id:  this.new_account.account_type_id,
                        account_subtype_id: this.new_account.account_subtype_id
                    }
                    //TODO: code needs to be removed
                    // let issSubtypeExists = this.accounts && this.accounts.find(x=> x.account_subtype_id == this.new_account.account_subtype_id && x.id !== this.new_account.id);
                    try {
                        //TODO: code needs to be removed
                        // if(!issSubtypeExists){
                            this.errorClear(this.$options.name);
                            this.startLoading(this.$options.name);
                            if(this.isEdit) {
                                api.put(this, api.ACCOUNTING + 'account/' + this.new_account.id, data).then(r => {
                                    this.$emit('refetch');
                                    this.emitClose();
                                    this.displayMessageNotification('You’ve successfully updated account. ', false);
                                });
                            } else {
                                api.post(this, api.ACCOUNTING + 'account', data).then(r => {
                                    this.$emit('refetch');
                                    this.emitClose();
                                    this.displayMessageNotification('You’ve successfully added a new account. ', false);
                                });
                            }

                        //TODO: code needs to be removed
                        // }else{  
                        //     this.displayMessageNotification('Mutiple accounts of same subtype found. Please correct this.', true);
                        // }

                    } catch (error) {
                        this.displayMessageNotification(error, true);
                    }
                }
            })

        },

        displayMessageNotification(message, isError) {
            if(!isError) {
                this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
            } else {
                this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: message }], });
            }
        },

    }
}
</script>

<style scoped>
    .mrl--25px{
        margin-left: -25px;
        margin-right: -25px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .no-top-border{
        border-top: none;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    .border-bottom{
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
</style>

<style>
    .edit-acct-field .theme--light.v-label{
        color: rgba(0, 0, 0, 0.9);
    }
    .edit-acct-field > .v-input__control > .v-input__slot{
        padding: 0 !important;
    }
</style>
