<template>
  <div>
    <v-row class="mt-1 mb-0 d-flex align-center">
      <v-col cols="7" class="hb-text-light d-flex align-center">
        <hb-btn small color="secondary" v-if="!bulkEdit" @click="showSheetModal = true">Upload Spreadsheet</hb-btn>
        <hb-btn small color="secondary" :class="{'pl-3' : !bulkEdit}" @click="downloadSpreadSheetTemplate">Download Spreadsheet Template</hb-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="5" class="d-flex align-center justify-end">
        <hb-btn small color="secondary" class="pr-3" v-if="hasPermission('manage_gl_accounts') && accounts.length > 0 && !bulkEdit" @click="bulkEdit=true">Edit Chart of Accounts</hb-btn>
        <hb-btn small color="secondary" v-if="hasPermission('manage_gl_accounts')" @click="showAddAccountModal=true" :disabled="bulkEdit"> Add Account</hb-btn>
      </v-col>
    </v-row>
    <div class="mb-2">
      <gl-accounts 
        :accounts="accounts.filter(a => !a.is_group)" 
        @editItem="editItem" 
        @deleteItem="deleteItem"
        :bulkEdit="bulkEdit"
        @cancelBulkEdit="cancelBulkEdit"
        @saveBulk="confirmSaveBulk"
      ></gl-accounts>
    </div>
    <v-row v-if="accounts.length == 0" justify="center" class="mt-6 mb-2">
         <a class="hb-link ml-4" @click="uploadSpreadSheetTemplate">Click here to Upload Spreadsheet Template</a>
    </v-row>
    <upload-file
        v-model="showSheetModal"
        v-if="showSheetModal"
        type="sheet"
        model="accounting"
        foreign_id="coa_spreadsheet"
        size="lg"
        slot="body"
        @close="showSheetModal=false"
        @save="appendSpreadsheet">
    </upload-file>
    <edit-account @close="closeDialog" :accounts="accounts.filter(a => !a.is_group)" :selected="selected" @refetch="fetchAccounting" v-if="showAddAccountModal" v-model="showAddAccountModal"></edit-account>
    <hb-modal
      v-model="showDeleteModal"
      v-if="showDeleteModal"
      size="medium"
      show-help-link
      :title="isActiveProcessFound ? 'Cannot Delete Account' : 'Delete Account'"
      confirmation
      @close="restSelected"
    >
      <template v-slot:content>
        <span v-if="isActiveProcessFound">
          <span v-for="item in deleteAccountingAccounts">
            <span v-if="getActiveProcessDetails(item).length">
              <div class="py-4 px-6">
                You can delete the {{item.name}} Account by removing it from the listed processes.
              </div>
              <div class="pb-6 px-10">
                <li v-for="i in getActiveProcessDetails(item)">{{i}}</li>
              </div>
            </span>
          </span>
        </span>
        <span v-else>
          <div class="pt-6 pb-4 px-6">
            You are about to delete this account. This will remove the account from your journal template setup. Review your journal templates afterward for any inconsistencies.
        </div>
        <div class="pb-6 px-6">
          Are you sure you want to delete this account?
        </div>
        </span>
      </template>
      <template v-slot:actions>
        <hb-btn color="destructive" @click="deleteAccount" :disabled="isActiveProcessFound" :loading="isLoading($options.name)">Delete</hb-btn>
      </template>
    </hb-modal>
    <hb-modal
      v-model="showBulkUpdateModal"
      v-if="showBulkUpdateModal"
      show-help-link
      size="medium"
      title="Confirm Changes"
      confirmation
    >
      <template v-slot:content>
        <div class="pa-6">
          You are about to make {{updateCount | numTowords}} changes to your chart of accounts. This may affect your journal templates, so make sure to review your journal templates for any inconsistencies after confirming these changes. 
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" :loading="isLoading($options.name)" @click="saveBulk">Confirm</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>

import GlAccounts from './GlAccounts.vue';
import EditAccount from './EditAccount.vue'
import api from '../../../assets/api.js';
import UploadFile from '../../assets/UploadFile.vue';
import moment from 'moment'
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
  name: 'ChartOfAccount',
  data() {
    return {
      showAddAccountModal : false,
      showDeleteModal: false,
      showSheetModal: false,
      selected : null,
      accounts: [],
      bulkEdit: false,
      showBulkUpdateModal: false,
      bulkEditRows: [],
      accounts_index: 0,
      previousBulkRows: [],
      deleteAccountingAccounts: [],
      isActiveProcessFound: false,
    }
  },
  mixins:[notificationMixin],
  components: {
    GlAccounts,
    EditAccount,
    UploadFile
  },
  async created () {
    await this.fetchAccounting();
    this.previousBulkRows = this.accounts.slice();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission',
      accountingActiveProcesses: "accountingStore/accountingActiveProcesses",
    }),
  },
  methods: {
    ...mapActions({
      getAccountingActiveProcesses: "accountingStore/getAccountingActiveProcesses",
      setChartOfAccounts: "accountingStore/setChartOfAccounts"
    }),
    getActiveProcessDetails(item) {
      return this.accountingActiveProcesses.find(i => i.gl_account_id == item.id).processDetails;
    },
    isAccountingActiveProcesses() {
      let activeProcessFound = false;
      if(this.deleteAccountingAccounts?.length) {
        this.deleteAccountingAccounts.map(item => {
          let activeProcesses = this.getActiveProcessDetails(item);
          if(activeProcesses.length) {
            activeProcessFound = true;
            return activeProcessFound;
          }
        });
      }
      return activeProcessFound;
    },
    closeDialog(){
      this.showAddAccountModal = false;
      this.selected = null;
    },
    async fetchAccounting(){
      this.accounts_index = 0;
      let p = await api.get(this, api.ACCOUNTING + 'accounts');
      p.accounts.forEach(x => {
        x.index = this.accounts_index++;
      })
      this.accounts = p.accounts;
      await this.setChartOfAccounts(p);
    },
    editItem(item) {
      this.selected = item;
      this.showAddAccountModal = true;
    },
    async deleteItem(item){
      this.selected = item;
      this.deleteAccountingAccounts = [];
      this.isActiveProcessFound = false;
      this.deleteAccountingAccounts.push(item);
      await this.getAccountingActiveProcesses({glAccounts: this.deleteAccountingAccounts});
      this.isActiveProcessFound = this.isAccountingActiveProcesses();
      this.showDeleteModal = true;
    },
    uploadSpreadSheetTemplate(){
      this.showSheetModal = true;
    },
    downloadSpreadSheetTemplate(){
        api.get(this, api.ACCOUNTING + 'download-coa-template').then(async (r) => {
          var buffer = Buffer.from(r.buffer.data);
          var blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download =  'COA_Template' + moment().format('x');
          link.click();

        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve successfully downloaded the template.' });

      }).catch(err =>{
        console.log("errrroror:",err);
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }], });
      });
    },
    deleteAccount() {
      if(!this.selected.id) return;

      api.delete(this, api.ACCOUNTING + 'account/' + this.selected.id).then(async (r) => {
        await this.fetchAccounting();
        this.showDeleteModal = false;
        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve successfully deleted an account. Review your journal templates to make any necessary adjustments.' });
      }).catch(err =>{
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }], });
      });

    },
    async cancelBulkEdit() {
      this.bulkEdit = false;
      await this.fetchAccounting();
    },
    async confirmSaveBulk(data){
      this.bulkEditRows = data;
      let update_count = Math.abs(this.previousBulkRows.length - this.bulkEditRows.filter(x => x.id).length);
      
      if(update_count > 0) {
        this.deleteAccountingAccounts = this.previousBulkRows.filter(p => !this.bulkEditRows.some(b => b.id === p.id));
        await this.getAccountingActiveProcesses({glAccounts: this.deleteAccountingAccounts});
        this.isActiveProcessFound = this.isAccountingActiveProcesses();
        if(this.isActiveProcessFound) {
          this.showDeleteModal = true;
          return;
        }
      }

      this.bulkEditRows.forEach(item => {
        if(!item.id){
          update_count++;
        } else {
          let account = this.previousBulkRows.find(x => x.id === item.id);
          if(account && (account.code !== item.code || account.name !== item.name || account.category_id !== item.category_id || account.account_type_id !== item.account_type_id ||
                account.account_subtype_id !== item.account_subtype_id)
            ) {
            update_count++;
          }
        }
      });

      this.updateCount = update_count;

      if(!this.updateCount) {
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: 'No updates found. Make some changes to chart of account.' }], });
        return;
      }

      this.showBulkUpdateModal = true;
    },

    saveBulk(){

      let deletedAccounts = this.accounts.map(x=> x.id).filter(y => !this.bulkEditRows.map(x=>x.id).includes(y))
      let data = {
        accounts: this.bulkEditRows,
        deleted: deletedAccounts.map(x => ({ id: x}))
      }

      api.post(this, api.ACCOUNTING + 'account/bulk', data).then(async (r) => {
        await this.fetchAccounting();
        this.previousBulkRows = this.accounts.slice();
        this.bulkEdit = false;
        this.showBulkUpdateModal = false;

        this.showMessageNotification({ id: this.$options.name, type: "success", description: 'You’ve made changes to your chart of accounts. Review your journal templates for inconsistencies and to make any necessary adjustments.' });
      }).catch(err => {
        this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: err }], });
      });

      
    },

    restSelected() {
      this.selected = null;
    },
    
    appendSpreadsheet(data){
      if(!data.accounts.length) return;
      data.accounts.forEach(x => {
        x.index = this.accounts_index++;
        this.accounts.push(x);
      });
      this.bulkEdit = true;
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
